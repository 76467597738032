import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { feeGroup, feesTypes, paymentType } from '../../../core/common/selectoption/selectoption'
import { DatePicker } from "antd";
import { useLocation } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import CommonSelect from "../../../../core/common/commonSelect";
import {
  AdmissionNo,
  Hostel,
  PickupPoint,
  VehicleNumber,
  academicYear,
  allClass,
  allSection,
  bloodGroup,
  cast,
  gender,
  house,
  mothertongue,
  names,
  religion,
  rollno,
  roomNO,
  route,
  status,
} from "../../../../core/common/selectoption/selectoption";
import appstore from "../../../../store/appStore";
import { all_routes } from "../../../router/all_routes";

const AddStudent = () => {
  const routes = all_routes;
  const { appAxios, userData } = appstore();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [owner, setOwner] = useState<string[]>(["English", "Spanish"]);
  const [owner1, setOwner1] = useState<string[]>([]);
  const [owner2, setOwner2] = useState<string[]>([]);
  // const [defaultDate, setDefaultDate] = useState<dayjs.Dayjs | null>(null);
  const [newContents, setNewContents] = useState<object[]>([{}]);
  const [studentData, setStudentData] = useState<any>({
    medicalHistory: [],
    allergies: [],
    bankDetails: {},
    transportDetails: {},
    hostelDetails: {},
  });
  const [fatherData, setFatherData] = useState<any>({});
  const [motherData, setMotherData] = useState<any>({});
  const [guardianData, setGuardianData] = useState<any>({});
  const location = useLocation();
  const addNewContent = () => {
    setNewContents([...newContents, {}]);
  };
  const removeContent = (index: any) => {
    setNewContents(newContents.filter((_, i) => i !== index));
  };
  const addStudenData = async (event: any) => {
    event.preventDefault();
    const res = await appAxios({
      path: "/student/add",
      post: true,
      admin: false,
      obj: {
        studentData: {
          ...studentData,
        },
        motherData,
        fatherData,
        guardianData,
        orgId: userData.role == "ORGANIZATION" ? userData._id : userData.orgId,
      },
    });
    if (res.data.success) {
      toast.success("Uploaded! ");
    } else {
      toast.error(res.data.msg);
    }
    // console.log("studentData", studentData);
  };
  const handleChange = (e: any) => {
    const { value, name } = e.target;
    setStudentData({ ...studentData, [name]: value });
  };
  const fetchStudentData = async (id: any) => {
    const res = await appAxios({
      path: `/student/${id}`,
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setStudentData(res.data.data);
      if (res.data.data.fatherId) {
        setFatherData(res.data.data.fatherId);
      }
      if (res.data.data.motherId) {
        setMotherData(res.data.data.motherId);
      }
      if (res.data.data.guardianId) {
        setGuardianData(res.data.data.guardianId);
      }
      // toast.success("Uploaded! ");
    } else {
      toast.error(res.data.msg);
    }
  };
  const editStudentData = async (event: any) => {
    event.preventDefault();
    const searchParams = new URLSearchParams(window.location.search);
    const studentId = searchParams.get("studentId");
    const res = await appAxios({
      path: "/student",
      patch: true,
      admin: false,
      obj: {
        studentData,
        motherData,
        fatherData,
        guardianData,
        id: studentId,
      },
    });
    if (res.data.success) {
      setStudentData(res.data.data);
      toast.success("Student Updated! ");
    } else {
      toast.error(res.data.msg);
    }
  };
  useEffect(() => {
    if (location.pathname === routes.editStudent) {
      const searchParams = new URLSearchParams(window.location.search);
      const studentId = searchParams.get("studentId");
      if (studentId) {
        fetchStudentData(studentId);
      } else {
        navigate("/");
      }
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
      const day = String(today.getDate()).padStart(2, "0");
      // const formattedDate = `${month}-${day}-${year}`;
      // const defaultValue = dayjs(formattedDate);
      setIsEdit(true);
      setOwner(["English"]);
      setOwner1(["Medecine Name"]);
      setOwner2(["Allergy", "Skin Allergy"]);
      // setDefaultDate(defaultValue);
      // console.log(formattedDate, 11);
    } else {
      setIsEdit(false);
      // setDefaultDate(null);
    }
  }, [location.pathname]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="mb-1">{isEdit ? "Edit" : "Add"} Student</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.studentList}>Students</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {isEdit ? "Edit" : "Add"} Student
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={isEdit ? editStudentData : addStudenData}>
                {/* Personal Information */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-info-square-rounded fs-16" />
                      </span>
                      <h4 className="text-dark">Personal Information</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                          <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                            <i className="ti ti-photo-plus fs-16" />
                          </div>
                          <div className="profile-upload">
                            <div className="profile-uploader d-flex align-items-center">
                              <div className="drag-upload-btn mb-3">
                                Upload
                                <input
                                  type="file"
                                  className="form-control image-sign"
                                  multiple
                                />
                              </div>
                              <Link to="#" className="btn btn-primary mb-3">
                                Remove
                              </Link>
                            </div>
                            <p className="fs-12">
                              Upload image size 4MB, Format JPG, PNG, SVG
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row-cols-xxl-5 row-cols-md-6">
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Academic Year</label>
                          <CommonSelect
                            className="select"
                            options={academicYear}
                            name="academicYear"
                            onChange={handleChange}
                            defaultValue={isEdit ? academicYear[0] : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Admission Number</label>
                          <input
                            type="text"
                            required
                            placeholder="5682"
                            name="admissionNumber"
                            onChange={handleChange}
                            className="form-control"
                            defaultValue={studentData.admissionNumber}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Admission Date</label>
                          <div className="input-icon position-relative">
                            {isEdit ? (
                              <DatePicker
                                className="form-control datetimepicker"
                                format={{
                                  format: "DD-MM-YYYY",
                                  type: "mask",
                                }}
                                // value={studentData.admissionDate}
                                placeholder="Select Date"
                              />
                            ) : (
                              <DatePicker
                                className="form-control datetimepicker"
                                format={{
                                  format: "DD-MM-YYYY",
                                  type: "mask",
                                }}
                                onChange={(e: any, d) => {
                                  handleChange({
                                    target: {
                                      value: d,
                                      name: "admissionDate",
                                    },
                                  });
                                }}
                                defaultValue=""
                                placeholder="Select Date"
                              />
                            )}
                            <span className="input-icon-addon">
                              <i className="ti ti-calendar" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Roll Number</label>
                          <input
                            type="text"
                            className="form-control"
                            name="rollNumber"
                            onChange={handleChange}
                            defaultValue={studentData.rollNumber}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Status</label>
                          <CommonSelect
                            className="select"
                            options={status}
                            name="status"
                            onChange={handleChange}
                            defaultValue={studentData.status}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            onChange={handleChange}
                            defaultValue={studentData.firstName}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            onChange={handleChange}
                            defaultValue={studentData.lastName}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Class</label>
                          <CommonSelect
                            className="select"
                            options={allClass}
                            name="class"
                            onChange={handleChange}
                            defaultValue={studentData.class}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Section</label>
                          <CommonSelect
                            className="select"
                            options={allSection}
                            name="section"
                            onChange={handleChange}
                            defaultValue={studentData.section}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Gender</label>
                          <CommonSelect
                            className="select"
                            options={gender}
                            name="gender"
                            onChange={handleChange}
                            defaultValue={studentData.gender}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Date of Birth</label>
                          <div className="input-icon position-relative">
                            {isEdit ? (
                              <DatePicker
                                className="form-control datetimepicker"
                                format={{
                                  format: "DD-MM-YYYY",
                                  type: "mask",
                                }}
                                // value={studentData.dateOfBirth}
                                placeholder="Select Date"
                              />
                            ) : (
                              <DatePicker
                                className="form-control datetimepicker"
                                format={{
                                  format: "DD-MM-YYYY",
                                  type: "mask",
                                }}
                                name="dateOfBirth"
                                // onChange={handleChange}
                                onChange={(e: any, d) => {
                                  handleChange({
                                    target: {
                                      value: d,
                                      name: "dateOfBirth",
                                    },
                                  });
                                }}
                                defaultValue=""
                                placeholder="Select Date"
                              />
                            )}
                            <span className="input-icon-addon">
                              <i className="ti ti-calendar" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Blood Group</label>
                          <CommonSelect
                            className="select"
                            options={bloodGroup}
                            name="bloodGroup"
                            onChange={handleChange}
                            defaultValue={studentData.bloodGroup}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">House</label>
                          <CommonSelect
                            className="select"
                            options={house}
                            name="house"
                            onChange={handleChange}
                            defaultValue={studentData.house}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Religion</label>
                          <CommonSelect
                            className="select"
                            options={religion}
                            name="religion"
                            onChange={handleChange}
                            defaultValue={studentData.religion}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Category</label>
                          <CommonSelect
                            className="select"
                            options={cast}
                            name="cast"
                            onChange={handleChange}
                            defaultValue={studentData.cast}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Primary Contact Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="primaryContactNumber"
                            onChange={handleChange}
                            defaultValue={studentData.primaryContactNumber}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Email Address</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            onChange={handleChange}
                            defaultValue={studentData.email}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Caste</label>
                          <input
                            type="text"
                            className="form-control"
                            name="cast"
                            onChange={handleChange}
                            value={studentData.cast}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Mother Tongue</label>
                          <CommonSelect
                            className="select"
                            options={mothertongue}
                            name="motherTongue"
                            onChange={handleChange}
                            defaultValue={studentData.motherTongue}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Language Known</label>
                          <TagsInput
                            // className="input-tags form-control"
                            value={studentData?.languageKnown || []}
                            name="languageKnown"
                            // onChange={handleChange}
                            onChange={(e) => {
                              // console.log("e", e);
                              setStudentData({
                                ...studentData,
                                languageKnown: e,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Current Address</label>
                          <input
                            type="text"
                            className="form-control"
                            name="currentAddress"
                            onChange={handleChange}
                            value={studentData.currentAddress}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Permanent Address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="permanentAddress"
                            onChange={handleChange}
                            value={studentData.permanentAddress}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Personal Information */}
                {/* Parents & Guardian Information */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-user-shield fs-16" />
                      </span>
                      <h4 className="text-dark">
                        Parents &amp; Guardian Information
                      </h4>
                    </div>
                  </div>
                  <div className="card-body pb-0">
                    <div className="border-bottom mb-3">
                      <h5 className="mb-3">Father’s Info</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                              <i className="ti ti-photo-plus fs-16" />
                            </div>
                            <div className="profile-upload">
                              <div className="profile-uploader d-flex align-items-center">
                                <div className="drag-upload-btn mb-3">
                                  Upload
                                  <input
                                    type="file"
                                    className="form-control image-sign"
                                    multiple
                                  />
                                </div>
                                <Link to="#" className="btn btn-primary mb-3">
                                  Remove
                                </Link>
                              </div>
                              <p className="fs-12">
                                Upload image size 4MB, Format JPG, PNG, SVG
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Father Name</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setFatherData({
                                  ...fatherData,
                                  name: e.target.value,
                                });
                              }}
                              defaultValue={fatherData?.name}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setFatherData({
                                  ...fatherData,
                                  email: e.target.value,
                                });
                              }}
                              defaultValue={fatherData?.email}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setFatherData({
                                  ...fatherData,
                                  phoneNumber: e.target.value,
                                });
                              }}
                              defaultValue={fatherData?.phoneNumber}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Father Occupation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setFatherData({
                                  ...fatherData,
                                  occupation: e.target.value,
                                });
                              }}
                              defaultValue={fatherData?.occupation}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom mb-3">
                      <h5 className="mb-3">Mother’s Info</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                              <i className="ti ti-photo-plus fs-16" />
                            </div>
                            <div className="profile-upload">
                              <div className="profile-uploader d-flex align-items-center">
                                <div className="drag-upload-btn mb-3">
                                  Upload
                                  <input
                                    type="file"
                                    className="form-control image-sign"
                                    multiple
                                  />
                                </div>
                                <Link to="#" className="btn btn-primary mb-3">
                                  Remove
                                </Link>
                              </div>
                              <p className="fs-12">
                                Upload image size 4MB, Format JPG, PNG, SVG
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Mother Name</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setMotherData({
                                  ...motherData,
                                  name: e.target.value,
                                });
                              }}
                              defaultValue={motherData?.name}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setMotherData({
                                  ...motherData,
                                  email: e.target.value,
                                });
                              }}
                              defaultValue={motherData?.email}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setMotherData({
                                  ...motherData,
                                  phoneNumber: e.target.value,
                                });
                              }}
                              defaultValue={motherData?.phoneNumber}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Mother Occupation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setMotherData({
                                  ...motherData,
                                  occupation: e.target.value,
                                });
                              }}
                              defaultValue={motherData?.occupation}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 className="mb-3">Guardian Details</h5>
                      <div className="row">
                        <div className="col-md-12">
                          {/* <div className="mb-2">
                            <div className="d-flex align-items-center flex-wrap">
                              <label className="form-label text-dark fw-normal me-2">
                                If Guardian Is
                              </label>
                              <div className="form-check me-3 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="guardian"
                                  id="parents"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="parents"
                                >
                                  Parents
                                </label>
                              </div>
                              <div className="form-check me-3 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="guardian"
                                  id="guardian"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="guardian"
                                >
                                  Guardian
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="guardian"
                                  id="other"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="other"
                                >
                                  Others
                                </label>
                              </div>
                            </div>
                          </div> */}
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                              <i className="ti ti-photo-plus fs-16" />
                            </div>
                            <div className="profile-upload">
                              <div className="profile-uploader d-flex align-items-center">
                                <div className="drag-upload-btn mb-3">
                                  Upload
                                  <input
                                    type="file"
                                    className="form-control image-sign"
                                    multiple
                                  />
                                </div>
                                <Link to="#" className="btn btn-primary mb-3">
                                  Remove
                                </Link>
                              </div>
                              <p className="fs-12">
                                Upload image size 4MB, Format JPG, PNG, SVG
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Guardian Name</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setGuardianData({
                                  ...guardianData,
                                  name: e.target.value,
                                });
                              }}
                              defaultValue={guardianData?.name}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Guardian Relation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setGuardianData({
                                  ...guardianData,
                                  relation: e.target.value,
                                });
                              }}
                              defaultValue={guardianData?.relation}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setGuardianData({
                                  ...guardianData,
                                  phoneNumber: e.target.value,
                                });
                              }}
                              defaultValue={guardianData?.phoneNumber}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              onChange={(e) => {
                                setGuardianData({
                                  ...guardianData,
                                  email: e.target.value,
                                });
                              }}
                              defaultValue={guardianData?.email}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Occupation</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setGuardianData({
                                  ...guardianData,
                                  occupation: e.target.value,
                                });
                              }}
                              defaultValue={guardianData?.occupation}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setGuardianData({
                                  ...guardianData,
                                  address: e.target.value,
                                });
                              }}
                              defaultValue={guardianData?.address}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Parents & Guardian Information */}
                {/* Sibilings */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-users fs-16" />
                      </span>
                      <h4 className="text-dark">Sibilings</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="addsibling-info">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-2">
                            <label className="form-label">Sibling Info</label>
                            <div className="d-flex align-items-center flex-wrap">
                              <label className="form-label text-dark fw-normal me-2">
                                Is Sibling studying in the same school
                              </label>
                              <div className="form-check me-3 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="sibling"
                                  id="yes"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="sibling"
                                  id="no"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="no"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {newContents.map((data: any, index) => (
                          <div key={index} className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-3 col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">Name</label>
                                  <CommonSelect
                                    className="select"
                                    options={names}
                                    onChange={(e: any) => {
                                      let newArray = newContents || [];
                                      let stuData = {
                                        ...data,
                                        name: e.target.value,
                                      };
                                      newArray[index] = stuData;
                                      setNewContents(newArray);
                                    }}
                                    defaultValue={data?.name}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">Roll No</label>
                                  <CommonSelect
                                    className="select"
                                    options={rollno}
                                    onChange={(e: any) => {
                                      let newArray = newContents || [];
                                      let stuData = {
                                        ...data,
                                        rollno: e.target.value,
                                      };
                                      newArray[index] = stuData;
                                      setNewContents(newArray);
                                    }}
                                    defaultValue={data?.rollno}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Admission No
                                  </label>
                                  <CommonSelect
                                    className="select"
                                    options={AdmissionNo}
                                    onChange={(e: any) => {
                                      let newArray = newContents || [];
                                      let stuData = {
                                        ...data,
                                        admissionNo: e.target.value,
                                      };
                                      newArray[index] = stuData;
                                      setNewContents(newArray);
                                    }}
                                    defaultValue={data?.admissionNo}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="mb-3">
                                  <div className="d-flex align-items-center">
                                    <div className="w-100">
                                      <label className="form-label">
                                        Class
                                      </label>
                                      <CommonSelect
                                        className="select"
                                        options={allClass}
                                        onChange={(e: any) => {
                                          let newArray = newContents || [];
                                          let stuData = {
                                            ...data,
                                            class: e.target.value,
                                          };
                                          newArray[index] = stuData;
                                          setNewContents(newArray);
                                        }}
                                        defaultValue={data?.class}
                                      />
                                    </div>
                                    {newContents.length > 1 && (
                                      <div>
                                        <label className="form-label">
                                          &nbsp;
                                        </label>
                                        <Link
                                          to="#"
                                          className="trash-icon ms-3"
                                          onClick={() => removeContent(index)}
                                        >
                                          <i className="ti ti-trash-x" />
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="border-top pt-3">
                      <Link
                        to="#"
                        onClick={addNewContent}
                        className="add-sibling btn btn-primary d-inline-flex align-items-center"
                      >
                        <i className="ti ti-circle-plus me-2" />
                        Add New
                      </Link>
                    </div>
                  </div>
                </div>
                {/* /Sibilings */}
                {/* Address */}
                {/* <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-map fs-16" />
                      </span>
                      <h4 className="text-dark">Address</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Current Address</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              isEdit
                                ? "3495 Red Hawk Road, Buffalo Lake, MN 55314"
                                : undefined
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Permanent Address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              isEdit
                                ? "3495 Red Hawk Road, Buffalo Lake, MN 55314"
                                : undefined
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* /Address */}
                {/* Transport Information */}
                <div className="card">
                  <div className="card-header bg-light d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-bus-stop fs-16" />
                      </span>
                      <h4 className="text-dark">Transport Information</h4>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Route</label>
                          <CommonSelect
                            className="select"
                            options={route}
                            onChange={(e: any) => {
                              setStudentData({
                                ...studentData,
                                transportDetails: {
                                  ...studentData.transportDetails,
                                  route: e.target.value,
                                },
                              });
                            }}
                            defaultValue={studentData?.transportDetails?.route}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Vehicle Number</label>
                          <CommonSelect
                            className="select"
                            options={VehicleNumber}
                            onChange={(e: any) => {
                              setStudentData({
                                ...studentData,
                                transportDetails: {
                                  ...studentData.transportDetails,
                                  vehicleNumber: e.target.value,
                                },
                              });
                            }}
                            defaultValue={
                              studentData?.transportDetails?.vehicleNumber
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Pickup Point</label>
                          <CommonSelect
                            className="select"
                            options={PickupPoint}
                            onChange={(e: any) => {
                              setStudentData({
                                ...studentData,
                                transportDetails: {
                                  ...studentData.transportDetails,
                                  pickUpPoint: e.target.value,
                                },
                              });
                            }}
                            defaultValue={
                              studentData?.transportDetails?.pickUpPoint
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Transport Information */}
                {/* Hostel Information */}
                <div className="card">
                  <div className="card-header bg-light d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building-fortress fs-16" />
                      </span>
                      <h4 className="text-dark">Hostel Information</h4>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Hostel</label>
                          <CommonSelect
                            className="select"
                            options={Hostel}
                            onChange={(e: any) => {
                              setStudentData({
                                ...studentData,
                                hostelDetails: {
                                  ...studentData.hostelDetails,
                                  name: e.target.value,
                                },
                              });
                            }}
                            defaultValue={studentData?.hostelDetails?.name}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Room No</label>
                          <CommonSelect
                            className="select"
                            options={roomNO}
                            onChange={(e: any) => {
                              setStudentData({
                                ...studentData,
                                hostelDetails: {
                                  ...studentData.hostelDetails,
                                  roomNo: e.target.value,
                                },
                              });
                            }}
                            defaultValue={studentData?.hostelDetails?.roomNo}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Hostel Information */}
                {/* Documents */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-file fs-16" />
                      </span>
                      <h4 className="text-dark">Documents</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-2">
                          <div className="mb-3">
                            <label className="form-label mb-1">
                              Medical Condition
                            </label>
                            <p>Upload image size of 4MB, Accepted Format PDF</p>
                          </div>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                              <i className="ti ti-file-upload me-1" />
                              Change
                              <input
                                type="file"
                                className="form-control image_sign"
                                multiple
                              />
                            </div>
                            {isEdit ? (
                              <p className="mb-2">BirthCertificate.pdf</p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-2">
                          <div className="mb-3">
                            <label className="form-label mb-1">
                              Upload Transfer Certificate
                            </label>
                            <p>Upload image size of 4MB, Accepted Format PDF</p>
                          </div>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="btn btn-primary drag-upload-btn mb-2">
                              <i className="ti ti-file-upload me-1" />
                              Upload Document
                              <input
                                type="file"
                                className="form-control image_sign"
                                multiple
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Documents */}
                {/* Medical History */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-medical-cross fs-16" />
                      </span>
                      <h4 className="text-dark">Medical History</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-2">
                          <label className="form-label">
                            Medical Condition
                          </label>
                          <div className="d-flex align-items-center flex-wrap">
                            <label className="form-label text-dark fw-normal me-2">
                              Medical Condition of a Student
                            </label>
                            <div className="form-check me-3 mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="medicalCondition"
                                id="good"
                                onChange={() => {
                                  setStudentData({
                                    ...studentData,
                                    medicalCondition: "good",
                                  });
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="good"
                              >
                                Good
                              </label>
                            </div>
                            <div className="form-check me-3 mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="medicalCondition"
                                id="bad"
                                onChange={() => {
                                  setStudentData({
                                    ...studentData,
                                    medicalCondition: "bad",
                                  });
                                }}
                              />
                              <label className="form-check-label" htmlFor="bad">
                                Bad
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="medicalCondition"
                                id="others"
                                onChange={() => {
                                  setStudentData({
                                    ...studentData,
                                    medicalCondition: "others",
                                  });
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="others"
                              >
                                Others
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Allergies</label>

                        <TagsInput
                          // className="input-tags form-control"
                          value={studentData?.allergies || []}
                          onChange={(e) => {
                            // console.log("e", e);
                            setStudentData({
                              ...studentData,
                              allergies: e,
                            });
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Medications</label>
                        <TagsInput
                          // className="input-tags form-control"
                          value={studentData?.medicalHistory || []}
                          // onChange={setOwner1}
                          onChange={(e) => {
                            // console.log("e", e);
                            setStudentData({
                              ...studentData,
                              medicalHistory: e,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Medical History */}
                {/* Previous School details */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building fs-16" />
                      </span>
                      <h4 className="text-dark">Previous School Details</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">School Name</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={studentData.previousSchoolName}
                            name="previousSchoolName"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={studentData.previousSchoolAddress}
                            name="previousSchoolAddress"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Previous School details */}
                {/* Other Details */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building-bank fs-16" />
                      </span>
                      <h4 className="text-dark">Other Details</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="mb-3">
                          <label className="form-label">Bank Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={studentData?.bankDetails?.bankName}
                            defaultValue={
                              isEdit ? "Bank of America" : undefined
                            }
                            onChange={(e: any) => {
                              setStudentData({
                                ...studentData,
                                bankDetails: {
                                  ...studentData.bankDetails,
                                  bankName: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="mb-3">
                          <label className="form-label">Branch</label>
                          <input
                            type="text"
                            className="form-control"
                            value={studentData?.bankDetails?.bankBranch}
                            onChange={(e: any) => {
                              setStudentData({
                                ...studentData,
                                bankDetails: {
                                  ...studentData.bankDetails,
                                  bankBranch: e.target.value,
                                },
                              });
                            }}
                            defaultValue={isEdit ? "Cincinnati" : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="mb-3">
                          <label className="form-label">IFSC Number</label>
                          <input
                            type="text"
                            className="form-control"
                            value={studentData?.bankDetails?.ifscCode}
                            onChange={(e: any) => {
                              setStudentData({
                                ...studentData,
                                bankDetails: {
                                  ...studentData.bankDetails,
                                  ifscCode: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">
                            Other Information
                          </label>
                          <textarea
                            className="form-control"
                            rows={3}
                            value={studentData?.otherDetails}
                            onChange={(e: any) => {
                              setStudentData({
                                ...studentData,
                                otherDetails: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Other Details */}
                <div className="text-end">
                  <button type="button" className="btn btn-light me-3">
                    Cancel
                  </button>
                  <button
                    // onClick={() => {
                    //   addStudent();
                    // }}
                    className="btn btn-primary"
                  >
                    {isEdit ? "Edit Student" : "Add Student"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default AddStudent;
