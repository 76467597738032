import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import appstore from "../../../../store/appStore";
import { all_routes } from "../../../router/all_routes";
import StudentModals from "../studentModals";
import StudentBreadcrumb from "./studentBreadcrumb";
import StudentSidebar from "./studentSidebar";

const StudentDetails = () => {
  const routes = all_routes;
  const { appAxios } = appstore();
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState<any>({
    medicalHistory: [],
    allergies: [],
    bankDetails: {},
    transportDetails: {},
    hostelDetails: {},
  });
  const [fatherData, setFatherData] = useState<any>({});
  const [motherData, setMotherData] = useState<any>({});
  const [guardianData, setGuardianData] = useState<any>({});
  const fetchStudentData = async (id: any) => {
    const res = await appAxios({
      path: `/student/${id}`,
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setStudentData(res.data.data);
      if (res.data.data.fatherId) {
        setFatherData(res.data.data.fatherId);
      }
      if (res.data.data.motherId) {
        setMotherData(res.data.data.motherId);
      }
      if (res.data.data.guardianId) {
        setGuardianData(res.data.data.guardianId);
      }
      // toast.success("Uploaded! ");
    } else {
      toast.error(res.data.msg);
    }
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const studentId = searchParams.get("studentId");
    if (studentId) {
      fetchStudentData(studentId);
    } else {
      navigate("/");
    }
  }, []);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            {/* Page Header */}
            <StudentBreadcrumb />
            {/* /Page Header */}
          </div>
          <div className="row">
            {/* Student Information */}
            <StudentSidebar studentData={studentData} />
            {/* /Student Information */}
            <div className="col-xxl-9 col-xl-8">
              <div className="row">
                <div className="col-md-12">
                  {/* List */}
                  <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                    <li>
                      <Link
                        to={routes.studentDetail}
                        className="nav-link active"
                      >
                        <i className="ti ti-school me-2" />
                        Student Details
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentTimeTable} className="nav-link">
                        <i className="ti ti-table-options me-2" />
                        Time Table
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentLeaves} className="nav-link">
                        <i className="ti ti-calendar-due me-2" />
                        Leave &amp; Attendance
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentFees} className="nav-link">
                        <i className="ti ti-report-money me-2" />
                        Fees
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentResult} className="nav-link">
                        <i className="ti ti-bookmark-edit me-2" />
                        Exam &amp; Results
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentLibrary} className="nav-link">
                        <i className="ti ti-books me-2" />
                        Library
                      </Link>
                    </li>
                  </ul>
                  {/* /List */}
                  {/* Parents Information */}
                  <div className="card">
                    <div className="card-header">
                      <h5>Parents Information</h5>
                    </div>
                    <div className="card-body">
                      <div className="border rounded p-3 pb-0 mb-3">
                        <div className="row">
                          <div className="col-sm-6 col-lg-4">
                            <div className="d-flex align-items-center mb-3">
                              <span className="avatar avatar-lg flex-shrink-0">
                                <ImageWithBasePath
                                  src="assets/img/parents/parent-13.jpg"
                                  className="img-fluid rounded"
                                  alt="img"
                                />
                              </span>
                              <div className="ms-2 overflow-hidden">
                                <h6 className="text-truncate">
                                  {fatherData?.name}
                                </h6>
                                <p className="text-primary">Father</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">Phone</p>
                              <p>{fatherData?.phoneNumber}</p>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="mb-3 overflow-hidden me-3">
                                <p className="text-dark fw-medium mb-1">
                                  Email
                                </p>
                                <p className="text-truncate">
                                  {fatherData?.email}
                                </p>
                              </div>
                              <Link
                                to="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                aria-label="Print"
                                data-bs-original-title="Reset Password"
                                className="btn btn-dark btn-icon btn-sm mb-3"
                              >
                                <i className="ti ti-lock-x" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border rounded p-3 pb-0 mb-3">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 ">
                            <div className="d-flex align-items-center mb-3">
                              <span className="avatar avatar-lg flex-shrink-0">
                                <ImageWithBasePath
                                  src="assets/img/parents/parent-14.jpg"
                                  className="img-fluid rounded"
                                  alt="img"
                                />
                              </span>
                              <div className="ms-2 overflow-hidden">
                                <h6 className="text-truncate">
                                  {motherData.name}
                                </h6>
                                <p className="text-primary">Mother</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 ">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">Phone</p>
                              <p>{motherData?.phoneNumber}</p>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="mb-3 overflow-hidden me-3">
                                <p className="text-dark fw-medium mb-1">
                                  Email
                                </p>
                                <p className="text-truncate">
                                  {motherData?.email}
                                </p>
                              </div>
                              <Link
                                to="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                aria-label="Print"
                                data-bs-original-title="Reset Password"
                                className="btn btn-dark btn-icon btn-sm mb-3"
                              >
                                <i className="ti ti-lock-x" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border rounded p-3 pb-0">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6">
                            <div className="d-flex align-items-center mb-3">
                              <span className="avatar avatar-lg flex-shrink-0">
                                <ImageWithBasePath
                                  src="assets/img/parents/parent-13.jpg"
                                  className="img-fluid rounded"
                                  alt="img"
                                />
                              </span>
                              <div className="ms-2 overflow-hidden">
                                <h6 className="text-truncate">
                                  Jerald Vicinius
                                </h6>
                                <p className="text-primary">
                                  {guardianData?.name} ({guardianData?.relation})
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">Phone</p>
                              <p>{guardianData?.phoneNumber}</p>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="mb-3 overflow-hidden me-3">
                                <p className="text-dark fw-medium mb-1">
                                  Email
                                </p>
                                <p className="text-truncate">
                                  {guardianData?.email}
                                </p>
                              </div>
                              <Link
                                to="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                aria-label="Print"
                                data-bs-original-title="Reset Password"
                                className="btn btn-dark btn-icon btn-sm mb-3"
                              >
                                <i className="ti ti-lock-x" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Parents Information */}
                </div>
                {/* Documents */}
                <div className="col-xxl-6 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <h5>Documents</h5>
                    </div>
                    <div className="card-body">
                      <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between mb-3 p-2">
                        <div className="d-flex align-items-center overflow-hidden">
                          <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                            <i className="ti ti-pdf fs-15" />
                          </span>
                          <div className="ms-2">
                            <p className="text-truncate fw-medium text-dark">
                              BirthCertificate.pdf
                            </p>
                          </div>
                        </div>
                        <Link to="#" className="btn btn-dark btn-icon btn-sm">
                          <i className="ti ti-download" />
                        </Link>
                      </div>
                      <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between p-2">
                        <div className="d-flex align-items-center overflow-hidden">
                          <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                            <i className="ti ti-pdf fs-15" />
                          </span>
                          <div className="ms-2">
                            <p className="text-truncate fw-medium text-dark">
                              Transfer Certificate.pdf
                            </p>
                          </div>
                        </div>
                        <Link to="#" className="btn btn-dark btn-icon btn-sm">
                          <i className="ti ti-download" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Documents */}
                {/* Address */}
                <div className="col-xxl-6 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <h5>Address</h5>
                    </div>
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-3">
                        <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                          <i className="ti ti-map-pin-up" />
                        </span>
                        <div>
                          <p className="text-dark fw-medium mb-1">
                            Current Address
                          </p>
                          <p>{studentData?.currentAddress}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                          <i className="ti ti-map-pins" />
                        </span>
                        <div>
                          <p className="text-dark fw-medium mb-1">
                            Permanent Address
                          </p>
                          <p>{studentData?.permanentAddress}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Address */}
                {/* Previous School Details */}
                <div className="col-xxl-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>Previous School Details</h5>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <p className="text-dark fw-medium mb-1">
                              Previous School Name
                            </p>
                            <p>{studentData?.previousSchoolAddress}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <p className="text-dark fw-medium mb-1">
                              School Address
                            </p>
                            <p>{studentData?.previousSchoolAddress}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Previous School Details */}
                {/* Bank Details */}
                <div className="col-xxl-6 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <h5>Bank Details</h5>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="text-dark fw-medium mb-1">
                              Bank Name
                            </p>
                            <p>{studentData?.bankDetails?.bankName}</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="text-dark fw-medium mb-1">Branch</p>
                            <p>{studentData?.bankDetails?.branch}</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="text-dark fw-medium mb-1">IFSC</p>
                            <p>{studentData?.bankDetails?.ifscCode}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Bank Details */}
                {/* Medical History */}
                <div className="col-xxl-6 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <h5>Medical History</h5>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <p className="text-dark fw-medium mb-1">
                              Known Allergies
                            </p>
                            {studentData?.allergies?.map(
                              (el: any, key: any) => {
                                return (
                                  <span
                                    className="badge bg-light text-dark"
                                    key={key}
                                  >
                                    {el}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <p className="text-dark fw-medium mb-1">
                              Medications
                            </p>
                            {studentData?.medicalHistory?.map(
                              (el: any, key: any) => {
                                return (
                                  <span
                                    className="badge bg-light text-dark"
                                    key={key}
                                  >
                                    {el}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Medical History */}
                {/* Other Info */}
                <div className="col-xxl-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>Other Info</h5>
                    </div>
                    <div className="card-body">
                      <p>{studentData?.otherDetails}</p>
                    </div>
                  </div>
                </div>
                {/* /Other Info */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <StudentModals />
    </>
  );
};

export default StudentDetails;
