// Students
  // Parents
  // Guardians
  // Teachers
  
  // Classes
  // Class Room
  // Class Routine
  // Section
  // Subject
  // Syllabus
  // Time Table
  // Home Work
  // Examinations
  // Reasons
  
  // Fees Collection
  // Library
  // Sports
  // Players
  // Hostel
  // Transport
  
  // Staffs
  // Departments
  // Designation
  // Attendance
  // Leaves
  // Holidays
  // Payroll
  
  // Accounts
  // Notice Board
  // Events
  // Attendance Report
  // Class Report
  // Student Report
  // Grade Report
  // Leave Report
  // Fees Report
  
  // Transactions
  
  // Contact Messages
  // Tickets
  
  // Profile
  
  // General Settings
  // Website Settings
  // App Setting
  // System Settings
  // Financial Settings
  // Academic Settings
  
  // Documentation
export const permission = [
    {
        
        modules: "Students",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Parents",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Guardians",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Teachers",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Classes",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Class Room",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Class Routine",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Section",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Subject",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Syllabus",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Time Table",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Home Work",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Examinations",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Fees Collection",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Library",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Sports",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Players",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Hostel",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Transport",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Staffs",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    
    {
        
        modules: "Designation",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Attendance",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Leaves",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Holidays",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Payroll",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Accounts",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Notice Board",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        
        modules: "Events",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Attendance Report",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Class Report",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Student Report",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Grade Report",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Leave Report",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Fees Report",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Transactions",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Contact Messages",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Tickets",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Profile",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "General Settings",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Website Settings",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "App Setting",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "System Settings",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Financial Settings",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Academic Settings",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
    {
        modules: "Documentation",
        created: "",
        view: "",
        edit: "",
        delete: "",
        allowAll: ""
    },
  ];
  