import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { feeGroup, feesTypes, paymentType } from '../../../core/common/selectoption/selectoption'
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import CommonSelect from "../../../../core/common/commonSelect";
import {
  Contract,
  Hostel,
  Marital,
  PickupPoint,
  Shift,
  VehicleNumber,
  allClass,
  allSubject,
  bloodGroup,
  gender,
  roomNO,
  route,
  status,
} from "../../../../core/common/selectoption/selectoption";
import appstore from "../../../../store/appStore";
import { all_routes } from "../../../router/all_routes";

const TeacherForm = () => {
  const routes = all_routes;
  const { appAxios, userData } = appstore();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [owner, setOwner] = useState<string[]>([]);
  const [owner1, setOwner1] = useState<string[]>([]);
  const [owner2, setOwner2] = useState<string[]>([]);
  const [teacherData, setTeacherData] = useState<any>({});
  const [payrollData, setPayrollData] = useState<any>({});
  const [defaultDate, setDefaultDate] = useState<dayjs.Dayjs | null>(null);
  const [newContents, setNewContents] = useState<number[]>([0]);
  const location = useLocation();
  const addNewContent = () => {
    setNewContents([...newContents, newContents.length]);
  };
  const removeContent = (index: any) => {
    setNewContents(newContents.filter((_, i) => i !== index));
  };
  const handleChange = (e: any) => {
    const { value, name } = e.target;
    setTeacherData({ ...teacherData, [name]: value });
  };
  const fetchTeacherData = async (id: any) => {
    const res = await appAxios({
      path: `/teacher/${id}`,
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setTeacherData(res.data.data);
      // if (res.data.data.fatherId) {
      //   setFatherData(res.data.data.fatherId);
      // }

      // toast.success("Uploaded! ");
    } else {
      toast.error(res.data.msg);
    }
  };
  const editStudentData = async (event: any) => {
    event.preventDefault();
    const searchParams = new URLSearchParams(window.location.search);
    const teacherId = searchParams.get("teacherId");
    const res = await appAxios({
      path: "/teacher",
      patch: true,
      admin: false,
      obj: {
        teacherData,
        payrollData,
        id: teacherId,
      },
    });
    if (res.data.success) {
      setTeacherData(res.data.data);
      toast.success("Teacher Updated! ");
    } else {
      toast.error(res.data.msg);
    }
  };
  const addStudenData = async (event: any) => {
    event.preventDefault();
    const res = await appAxios({
      path: "/teacher/add",
      post: true,
      admin: false,
      obj: {
        teacherData: {
          ...teacherData,
        },
        payrollData,
        orgId: userData.role == "ORGANIZATION" ? userData._id : userData.orgId,
      },
    });
    if (res.data.success) {
      toast.success("Uploaded! ");
    } else {
      toast.error(res.data.msg);
    }
    // console.log("studentData", studentData);
  };
  useEffect(() => {
    if (location.pathname === routes.editTeacher) {
      const searchParams = new URLSearchParams(window.location.search);
      const teacherId = searchParams.get("teacherId");
      if (teacherId) {
        fetchTeacherData(teacherId);
      } else {
        navigate("/");
      }
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${month}-${day}-${year}`;
      const defaultValue = dayjs(formattedDate);
      setIsEdit(true);
      setOwner(["English"]);
      setOwner1(["Medecine Name"]);
      setOwner2(["Allergy", "Skin Allergy"]);
      setDefaultDate(defaultValue);
      console.log(formattedDate, 11);
    } else {
      setIsEdit(false);
      setDefaultDate(null);
    }
  }, [location.pathname]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="mb-1">{isEdit ? "Edit" : "Add"} Teacher</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.teacherList}>Teacher</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {isEdit ? "Edit" : "Add"} Teacher
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <form  onSubmit={isEdit ? editStudentData : addStudenData}>
                <>
                  {/* Personal Information */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-info-square-rounded fs-16" />
                        </span>
                        <h4 className="text-dark">Personal Information</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                              <i className="ti ti-photo-plus fs-16" />
                            </div>
                            <div className="profile-upload">
                              <div className="profile-uploader d-flex align-items-center">
                                <div className="drag-upload-btn mb-3">
                                  Upload
                                  <input
                                    type="file"
                                    className="form-control image-sign"
                                    multiple
                                  />
                                </div>
                                <Link to="#" className="btn btn-primary mb-3">
                                  Remove
                                </Link>
                              </div>
                              <p className="fs-12">
                                Upload image size 4MB, Format JPG, PNG, SVG
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row row-cols-xxl-5 row-cols-md-6">
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Teacher ID</label>
                            <input
                              type="text"
                              className="form-control"
                              name="teacherId"
                              onChange={handleChange}
                              value={teacherData?.teacherId}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              onChange={handleChange}
                              value={teacherData?.firstName}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              onChange={handleChange}
                              value={teacherData?.lastName}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Class</label>
                            <CommonSelect
                              className="select"
                              options={allClass}
                              name="classes"
                              onChange={handleChange}
                              defaultValue={teacherData?.classes}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Subject</label>
                            <CommonSelect
                              className="select"
                              options={allSubject}
                              name="subjects"
                              onChange={handleChange}
                              defaultValue={teacherData?.subjects}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Gender</label>
                            <CommonSelect
                              className="select"
                              options={gender}
                              name="gender"
                              onChange={handleChange}
                              defaultValue={teacherData?.gender}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Primary Contact Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="primaryContactNumber"
                              onChange={handleChange}
                              defaultValue={teacherData?.primaryContactNumber}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email Address</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              onChange={handleChange}
                              value={teacherData?.email}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Blood Group</label>
                            <CommonSelect
                              className="select"
                              options={bloodGroup}
                              name="bloodGroup"
                              onChange={handleChange}
                              defaultValue={teacherData?.bloodGroup}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Date of Joining
                            </label>
                            <div className="input-icon position-relative">
                              {/* <span className="input-icon-addon">
                                <i className="ti ti-calendar" />
                              </span> */}
                              <DatePicker
                                className="form-control datetimepicker"
                                format={{
                                  format: "DD-MM-YYYY",
                                  type: "mask",
                                }}
                                onChange={(e: any, d) => {
                                  handleChange({
                                    target: {
                                      value: d,
                                      name: "dateOfJoining",
                                    },
                                  });
                                }}
                                defaultValue=""
                                placeholder="Select Date"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Father’s Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="fathersName"
                              onChange={handleChange}
                              value={teacherData?.fathersName}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Mother’s Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="mothersName"
                              onChange={handleChange}
                              value={teacherData?.mothersName}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Date of Birth</label>
                            <div className="input-icon position-relative">
                              {isEdit ? (
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  value={defaultDate}
                                  placeholder="Select Date"
                                />
                              ) : (
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  onChange={(e: any, d) => {
                                    handleChange({
                                      target: {
                                        value: d,
                                        name: "dateOfJoining",
                                      },
                                    });
                                  }}
                                  defaultValue=""
                                  placeholder="Select Date"
                                />
                              )}

                              <span className="input-icon-addon">
                                <i className="ti ti-calendar" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Marital Status</label>
                            <CommonSelect
                              className="select"
                              options={Marital}
                              name="maritialStatus"
                              onChange={handleChange}
                              defaultValue={teacherData?.maritialStatus}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Language Known</label>
                            <TagsInput
                              // className="input-tags form-control"
                              value={teacherData?.languageKnown || []}
                              onChange={(e) => {
                                // console.log("e", e);
                                setTeacherData({
                                  ...teacherData,
                                  languageKnown: e,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Qualification</label>
                            <input
                              type="text"
                              className="form-control"
                              name="qualification"
                              onChange={handleChange}
                              value={teacherData?.qualification}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Work Experience
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="workExperience"
                              onChange={handleChange}
                              value={teacherData?.workExperience}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Previous School if Any
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="previousSchoolName"
                              onChange={handleChange}
                              value={teacherData?.previousSchoolName}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Previous School Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="previousSchoolAddress"
                              onChange={handleChange}
                              value={teacherData?.previousSchoolAddress}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Previous School Phone No
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="previousSchoolPhone"
                              onChange={handleChange}
                              value={teacherData?.previousSchoolPhone}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              name="address"
                              onChange={handleChange}
                              value={teacherData?.address}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Permanent Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="permanentAddress"
                              onChange={handleChange}
                              value={teacherData?.permanentAddress}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              PAN Number / ID Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="panNumber"
                              onChange={handleChange}
                              value={teacherData?.panNumber}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Status</label>
                            <CommonSelect
                              className="select"
                              options={status}
                              name="status"
                              onChange={handleChange}
                              defaultValue={teacherData?.status}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-12 col-xl-12">
                          <div className="mb-3">
                            <label className="form-label">Notes</label>
                            <textarea
                              className="form-control"
                              placeholder="Other Information"
                              rows={4}
                              name="notes"
                              onChange={handleChange}
                              value={teacherData?.notes}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Personal Information */}
                </>

                <>
                  {/* Payroll */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-user-shield fs-16" />
                        </span>
                        <h4 className="text-dark">Payroll</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">EPF No</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e: any) => {
                                setPayrollData({
                                  ...payrollData,
                                  epfNo: e.target.value,
                                });
                              }}
                              defaultValue={payrollData?.epfNo}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Basic Salary</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e: any) => {
                                setPayrollData({
                                  ...payrollData,
                                  basicSalary: e.target.value,
                                });
                              }}
                              defaultValue={payrollData?.basicSalary}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Contract Type</label>
                            <CommonSelect
                              className="select"
                              options={Contract}
                              onChange={(e: any) => {
                                setPayrollData({
                                  ...payrollData,
                                  contractType: e.target.value,
                                });
                              }}
                              defaultValue={payrollData?.contractType}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Work Shift</label>
                            <CommonSelect
                              className="select"
                              options={Shift}
                              onChange={(e: any) => {
                                setPayrollData({
                                  ...payrollData,
                                  workShift: e.target.value,
                                });
                              }}
                              defaultValue={payrollData?.workShift}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Work Location</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e: any) => {
                                setPayrollData({
                                  ...payrollData,
                                  workLocation: e.target.value,
                                });
                              }}
                              defaultValue={payrollData?.workLocation}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Date of Leaving
                            </label>
                            <div className="input-icon position-relative">
                              {isEdit ? (
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  value={defaultDate}
                                  placeholder="Select Date"
                                />
                              ) : (
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  onChange={(e: any, d) => {
                                    setPayrollData({
                                      ...payrollData,
                                      dateOfLeaving: d,
                                    });
                                  }}
                                  defaultValue={payrollData?.dateOfLeaving}
                                  placeholder="Select Date"
                                />
                              )}
                              <span className="input-icon-addon">
                                <i className="ti ti-calendar" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Payroll */}
                  {/* Leaves */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-users fs-16" />
                        </span>
                        <h4 className="text-dark">Leaves</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Medical Leaves</label>
                            <input
                              type="text"
                              className="form-control"
                              name="medicalLeaves"
                              onChange={(e) => {
                                setTeacherData({
                                  ...teacherData,
                                  leaves: {
                                    ...teacherData?.leaves,
                                    medicalLeaves: e.target.value,
                                  },
                                });
                              }}
                              value={teacherData?.leaves?.medicalLeaves}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Casual Leaves</label>
                            <input
                              type="text"
                              className="form-control"
                              name="casualLeaves"
                              onChange={(e) => {
                                setTeacherData({
                                  ...teacherData,
                                  leaves: {
                                    ...teacherData?.leaves,
                                    casualLeaves: e.target.value,
                                  },
                                });
                              }}
                              value={teacherData?.leaves?.casualLeaves}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Maternity Leaves
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="maternityLeaves"
                              onChange={(e) => {
                                setTeacherData({
                                  ...teacherData,
                                  leaves: {
                                    ...teacherData.leaves,
                                    maternityLeaves: e.target.value,
                                  },
                                });
                              }}
                              value={teacherData?.leaves?.maternityLeaves}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Sick Leaves</label>
                            <input
                              type="text"
                              className="form-control"
                              name="sickLeaves"
                              onChange={(e) => {
                                setTeacherData({
                                  ...teacherData,
                                  leaves: {
                                    ...teacherData?.leaves,
                                    sickLeaves: e.target.value,
                                  },
                                });
                              }}
                              value={teacherData?.leaves?.sickLeaves}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Leaves */}
                  {/* Bank Details */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-map fs-16" />
                        </span>
                        <h4 className="text-dark">Bank Account Detail</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="bankName"
                              onChange={(e) => {
                                setTeacherData({
                                  ...teacherData,
                                  bandDetails: {
                                    ...teacherData?.bandDetails,
                                    accountName: e.target.value,
                                  },
                                });
                              }}
                              value={teacherData?.bandDetails?.accountName}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="accountNumber"
                              onChange={(e) => {
                                setTeacherData({
                                  ...teacherData,
                                  bandDetails: {
                                    ...teacherData.bandDetails,
                                    accountNumber: e.target.value,
                                  },
                                });
                              }}
                              value={teacherData?.bandDetails?.accountNumber}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Bank Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="bankName"
                              onChange={(e) => {
                                setTeacherData({
                                  ...teacherData,
                                  bandDetails: {
                                    ...teacherData.bandDetails,
                                    bankName: e.target.value,
                                  },
                                });
                              }}
                              value={teacherData?.bandDetails?.bankName}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">IFSC Code</label>
                            <input
                              type="text"
                              className="form-control"
                              name="ifscCode"
                              onChange={(e) => {
                                setTeacherData({
                                  ...teacherData,
                                  bandDetails: {
                                    ...teacherData.bandDetails,
                                    ifscCode: e.target.value,
                                  },
                                });
                              }}
                              value={teacherData?.bandDetails?.ifscCode}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Branch Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="branchName"
                              onChange={(e) => {
                                setTeacherData({
                                  ...teacherData,
                                  bandDetails: {
                                    ...teacherData.bandDetails,
                                    branchName: e.target.value,
                                  },
                                });
                              }}
                              value={teacherData?.bandDetails?.branchName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Bank Details */}
                </>

                {/* Transport Information */}
                <div className="card">
                  <div className="card-header bg-light d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-bus-stop fs-16" />
                      </span>
                      <h4 className="text-dark">Transport Information</h4>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Route</label>
                          <CommonSelect
                            className="select"
                            options={route}
                            onChange={(e: any) => {
                              setTeacherData({
                                ...teacherData,
                                transportDetails: {
                                  ...teacherData.transportDetails,
                                  route: e.target.value,
                                },
                              });
                            }}
                            defaultValue={teacherData?.transportDetails?.route}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Vehicle Number</label>
                          <CommonSelect
                            className="select"
                            options={VehicleNumber}
                            onChange={(e: any) => {
                              setTeacherData({
                                ...teacherData,
                                transportDetails: {
                                  ...teacherData.transportDetails,
                                  vehicleNumber: e.target.value,
                                },
                              });
                            }}
                            defaultValue={
                              teacherData?.transportDetails?.vehicleNumber
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Pickup Point</label>
                          <CommonSelect
                            className="select"
                            options={PickupPoint}
                            onChange={(e: any) => {
                              setTeacherData({
                                ...teacherData,
                                transportDetails: {
                                  ...teacherData.transportDetails,
                                  pickUpPoint: e.target.value,
                                },
                              });
                            }}
                            defaultValue={
                              teacherData?.transportDetails?.pickUpPoint
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Transport Information */}
                {/* Hostel Information */}
                <div className="card">
                  <div className="card-header bg-light d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building-fortress fs-16" />
                      </span>
                      <h4 className="text-dark">Hostel Information</h4>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Hostel</label>
                          <CommonSelect
                            className="select"
                            options={Hostel}
                            onChange={(e: any) => {
                              setTeacherData({
                                ...teacherData,
                                hostelDetails: {
                                  ...teacherData.hostelDetails,
                                  name: e.target.value,
                                },
                              });
                            }}
                            defaultValue={teacherData?.hostelDetails?.name}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Room No</label>
                          <CommonSelect
                            className="select"
                            options={roomNO}
                            onChange={(e: any) => {
                              setTeacherData({
                                ...teacherData,
                                hostelDetails: {
                                  ...teacherData.hostelDetails,
                                  roomNo: e.target.value,
                                },
                              });
                            }}
                            defaultValue={teacherData?.hostelDetails?.roomNo}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Hostel Information */}
                <>
                  {/* Social Media Links */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-building fs-16" />
                        </span>
                        <h4 className="text-dark">Social Media Links</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row rows-cols-xxl-5">
                        <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Facebook</label>
                            <input
                              type="text"
                              className="form-control"
                              name="facebookLink"
                              onChange={handleChange}
                              value={teacherData?.facebookLink}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Instagram</label>
                            <input
                              type="text"
                              className="form-control"
                              name="instagramLink"
                              onChange={handleChange}
                              value={teacherData?.instagramLink}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Linked In</label>
                            <input
                              type="text"
                              className="form-control"
                              name="linkdinLink"
                              onChange={handleChange}
                              value={teacherData?.linkdinLink}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Youtube</label>
                            <input
                              type="text"
                              className="form-control"
                              name="youtubelink"
                              onChange={handleChange}
                              value={teacherData?.youtubelink}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Twitter URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="youtubelink"
                              onChange={handleChange}
                              value={teacherData?.twitterlink}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Social Media Links */}
                  {/* Documents */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-file fs-16" />
                        </span>
                        <h4 className="text-dark">Documents</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-2">
                            <div className="mb-3">
                              <label className="form-label">
                                Upload Resume
                              </label>
                              <p>
                                Upload image size of 4MB, Accepted Format PDF
                              </p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                                <i className="ti ti-file-upload me-1" />
                                Change
                                <input
                                  type="file"
                                  className="form-control image_sign"
                                  multiple
                                />
                              </div>
                              <p className="mb-2">Resume.pdf</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-2">
                            <div className="mb-3">
                              <label className="form-label">
                                Upload Joining Letter
                              </label>
                              <p>
                                Upload image size of 4MB, Accepted Format PDF
                              </p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                                <i className="ti ti-file-upload me-1" />
                                Upload Document
                                <input
                                  type="file"
                                  className="form-control image_sign"
                                  multiple
                                />
                              </div>
                              <p className="mb-2">Resume.pdf</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Documents */}
                  {/* Password */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-file fs-16" />
                        </span>
                        <h4 className="text-dark">Password</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">New Password</label>
                            <input type="password" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Confirm Password
                            </label>
                            <input type="password" className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Password */}
                </>

                <div className="text-end">
                  <button type="button" className="btn btn-light me-3">
                    Cancel
                  </button>
                  <button
                    // onClick={() => {
                    //   addStudent();
                    // }}
                    className="btn btn-primary"
                  >
                    {isEdit ? "Edit Teacher" : "Add Teacher"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default TeacherForm;
