import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import appstore from "../../../store/appStore";
import { all_routes } from "../../router/all_routes";
type PasswordField = "password";

const AdminLogin = () => {
  const routes = all_routes;
  const { appAxios, setLogin, setUserData, userData } = appstore();
  const navigation = useNavigate();
  const [inputDetails, setInputDetails] = useState({ email: "", password: "" });
  const navigationPath = () => {
    navigation(routes.adminDashboard);
  };
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
  });

  const togglePasswordVisibility = (field: PasswordField) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  const handleLogin = async () => {
    console.log("email", inputDetails);
    const res = await appAxios({
      path: "/admin/login",
      post: true,
      admin: false,
      obj: inputDetails,
    });
    if (res.data.success) {
      toast.success("Login successfull !");
      localStorage.setItem(
        process.env.REACT_APP_PUBLIC_APP_TOKEN + "_admin",
        res.data.token
      );
      setUserData({ ...userData, role: "ADMIN" });
      setLogin(true);
      navigation("/index");
      // return;
    }
  };
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5 mx-auto">
            {/* <form> */}
            <div className="d-flex flex-column justify-content-between vh-100">
              <div className=" mx-auto p-4 text-center">
                <ImageWithBasePath
                  src="assets/img/authentication/authentication-logo.svg"
                  className="logo logo-normal w-40 mt-50"
                  alt="Logo"
                />
              </div>
              <div className="card">
                <div className="card-body p-4">
                  <div className=" mb-4">
                    <h2 className="mb-2">Welcome</h2>
                    <p className="mb-0">Please enter your details to sign in</p>
                  </div>
                  <div className="mt-4"></div>
                  <div className="mb-3 ">
                    <label className="form-label">Email Address</label>
                    <div className="input-icon mb-3 position-relative">
                      <span className="input-icon-addon">
                        <i className="ti ti-mail" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={inputDetails.email}
                        onChange={(e) => {
                          setInputDetails({
                            ...inputDetails,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <label className="form-label">Password</label>
                    <div className="pass-group">
                      <input
                        type={passwordVisibility.password ? "text" : "password"}
                        className="pass-input form-control"
                        value={inputDetails.password}
                        onChange={(e) => {
                          setInputDetails({
                            ...inputDetails,
                            password: e.target.value,
                          });
                        }}
                      />
                      <span
                        className={`ti toggle-passwords ${
                          passwordVisibility.password ? "ti-eye" : "ti-eye-off"
                        }`}
                        onClick={() => togglePasswordVisibility("password")}
                      ></span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button
                      // type="submit"
                      className="btn btn-primary w-100"
                      onClick={handleLogin}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </div>
              <div className="p-4 text-center">
                <p className="mb-0 ">Copyright © 2024 - Academy Detail</p>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
