export const filterSideBarwithPermissionArray = (array, permissionsArray) => {
  return array.map((el) => {
    if (el.submenuItems) {
      el.submenuItems = el?.submenuItems?.filter((title) => {
        if (permissionsArray?.includes(title.label)) {
          return true;
        } else {
          return false;
        }
      });
      return el;
    } else {
      return el;
    }
  });
};
