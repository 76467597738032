import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import CommonSelect from "../../../core/common/commonSelect";
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../core/common/datePicker";
import {
  activeList,
  classSection,
  classSylabus,
} from "../../../core/common/selectoption/selectoption";
import TooltipOption from "../../../core/common/tooltipOption";
import { TableData } from "../../../core/data/interface";
import { classes } from "../../../core/data/json/classes";
import appstore from "../../../store/appStore";
import { all_routes } from "../../router/all_routes";

const Classes = () => {
  const routes = all_routes;
  const { appAxios, userData, allClasses, setAllClasses } = appstore();
  const [classData, setClassData] = useState<any>({});
  const data = classes;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = async (e: any) => {
    e.preventDefault();
    if (!userData._id || allClasses.length) {
      return;
    }
    const res = await appAxios({
      path: "/classes/add",
      post: true,
      admin: false,
      obj: {
        orgId: userData.role == "ORGANIZATION" ? userData._id : userData.orgId,
        classData: { ...classData },
      },
    });
    if (res.data.success) {
      // toast.success("Uploaded! ");
      setAllClasses(res.data.data);
      if (dropdownMenuRef.current) {
        dropdownMenuRef.current.classList.remove("show");
      }
    } else {
      toast.error(res.data.msg);
    }
  };
  const route = all_routes;
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
    },
    {
      title: "Class",
      dataIndex: "class",
      sorter: (a: TableData, b: TableData) => a.class.length - b.class.length,
    },
    {
      title: "Section",
      dataIndex: "section",
      sorter: (a: TableData, b: TableData) =>
        a.section.length - b.section.length,
    },
    {
      title: "No of Student",
      dataIndex: "noOfStudents",
      sorter: (a: TableData, b: TableData) =>
        a.noOfStudents.length - b.noOfStudents.length,
    },
    {
      title: "Subjects",
      dataIndex: "subject",
      render: (text: any) => (
        <>
          {text.map((el: any) => {
            return (
              <span className="badge badge-soft-success d-inline-flex align-items-center">
                <i className="ti ti-circle-filled fs-5 me-1"></i>
                {el}
              </span>
            );
          })}{" "}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Active" ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (id: any) => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <span
                    className="dropdown-item rounded-1"
                    onClick={() => {
                      let newClassData = allClasses.filter(
                        (el: any) => el._id == id
                      )[0];
                      setClassData(newClassData);
                      setTags(newClassData?.subject);
                      if (dropdownMenuRef.current) {
                        dropdownMenuRef.current.classList.add("show");
                      }
    //                   element.setAttribute("data-bs-toggle", "modal");
    // element.setAttribute("data-bs-target", "#add_class");
                      // className="btn btn-primary"
                      // data-bs-toggle="modal"
                      // data-bs-target="#add_class"
                    }}
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </span>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  const [tags, setTags] = useState<any>([]);
  const [showSubjectOptions, setShowSubjectOptions] = useState<any>(false);
  const [dropdownOptions, setDropdownOptions] = useState([
    "React",
    "JavaScript",
    "CSS",
    "HTML",
    "Node.js",
  ]);
  const handleAddTag = (tag: any) => {
    if (!tags.includes(tag)) {
      setTags((prevTags: any) => [...prevTags, tag]);
      setClassData({ ...classData, subjects: [...tags, tag] });
    }
    setShowSubjectOptions(false);
  };
  const editClass = async (e: any) => {
    e.preventDefault();
    if (!userData._id || allClasses.length) {
      return;
    }
    const res = await appAxios({
      path: "/classes/add",
      post: true,
      admin: false,
      obj: {
        orgId: userData.role == "ORGANIZATION" ? userData._id : userData.orgId,
        classData: { ...classData },
      },
    });
    if (res.data.success) {
      toast.success("Uploaded! ");
      setAllClasses(res.data.data);
    } else {
      toast.error(res.data.msg);
    }
  };
  const getAllClasses = async () => {
    console.log("allClasses", allClasses);
    console.log("userData", userData);
    if (!userData._id || allClasses.length) {
      return;
    }
    const res = await appAxios({
      path: "/classes/orgClasses",
      post: true,
      admin: false,
      obj: {
        id: userData.role == "ORGANIZATION" ? userData._id : userData.orgId,
      },
    });
    if (res.data.success) {
      // toast.success("Uploaded! ");
      setAllClasses(res.data.data);
    } else {
      toast.error(res.data.msg);
    }
  };
  useEffect(() => {
    getAllClasses();
  }, []);
  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Classes List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={route.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Classes </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Classes
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_class"
                >
                  <i className="ti ti-square-rounded-plus-filled me-2" />
                  Add Class
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Guardians List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Classes List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form onSubmit={handleApplyClick}>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <CommonSelect
                                className="select"
                                options={classSylabus}
                                defaultValue={classSylabus[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Section</label>
                              <CommonSelect
                                className="select"
                                options={classSection}
                                defaultValue={classSection[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Status</label>
                              <CommonSelect
                                className="select"
                                options={activeList}
                                defaultValue={activeList[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <button
                          // to="#"
                          className="btn btn-primary"
                          // onClick={handleApplyClick}
                        >
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Guardians List */}
              <Table
                columns={columns}
                dataSource={allClasses}
                Selection={true}
              />
              {/* /Guardians List */}
            </div>
          </div>
          {/* /Guardians List */}
        </div>
      </div>
      ;{/* /Page Wrapper */}
      <>
        {/* Add Classes */}
        <div className="modal fade" id="add_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Class</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form onSubmit={editClass}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Class Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={classData?.name}
                          onChange={(e: any) => {
                            setClassData({
                              ...classData,
                              name: e?.target?.value,
                            });
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Section</label>
                        <CommonSelect
                          className="select"
                          options={classSection}
                          defaultValue={classData?.section}
                          onChange={(e: any) => {
                            setClassData({
                              ...classData,
                              section: e?.target?.value,
                            });
                          }}
                        />
                      </div>
                      {/* <div className="mb-3">
                        <label className="form-label">No of Students</label>
                        <input type="text" className="form-control" />
                      </div> */}
                      {/* <div className="mb-3">
                        <label className="form-label">No of Subjects</label>
                        <input type="text" className="form-control" />
                      </div> */}

                      <div
                        className="mb-3"
                        style={{ position: "relative", height: "auto" }}
                      >
                        <label className="form-label">Subjects</label>
                        <TagsInput
                          value={tags}
                          onChange={setTags}
                          // ={''}
                          name="languageKnown"
                          onKeyUp={(e: any) => {
                            if (e.key == "Backspace") {
                              setShowSubjectOptions(false);
                            } else {
                              setShowSubjectOptions(true);
                            }
                            console.log("keyup ", e);
                          }}
                          onBlur={() => {
                            console.log("blue");
                          }}
                          onRemoved={() => {
                            console.log("onRemoved");
                          }}
                        />
                        {showSubjectOptions && (
                          <div style={{ position: "absolute", width: "100%" }}>
                            <ul
                              style={{
                                listStyle: "none",
                                padding: 0,
                                margin: "10px 0 0",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                maxHeight: "150px",
                                overflowY: "auto",
                                backgroundColor: "#fff",
                                zIndex: 1,
                              }}
                            >
                              {dropdownOptions
                                .filter((option) => !tags.includes(option))
                                .map((option, index) => (
                                  <li
                                    key={index}
                                    style={{
                                      padding: "8px",
                                      cursor: "pointer",
                                      borderBottom: "1px solid #ddd",
                                    }}
                                    onClick={() => {
                                      console.log("sdsad-called");
                                      handleAddTag(option);
                                    }}
                                  >
                                    {option}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      {/* <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm"
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <button
                    // to="#"
                    className="btn btn-primary"
                    // data-bs-dismiss="modal"
                  >
                    Add Class
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add Classes */}
        {/* Edit Classes */}
        <div className="modal fade" id="edit_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Class</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Class Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Class Name"
                          defaultValue="I"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Section</label>
                        <CommonSelect
                          className="select"
                          options={classSection}
                          defaultValue={classSection[0]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">No of Students</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter no of Students"
                          defaultValue={30}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">No of Subjects</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter no of Subjects"
                          // defaultValue={03}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit Classes */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
        {/* View Classes */}
        <div className="modal fade" id="view_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex align-items-center">
                  <h4 className="modal-title">Class Details</h4>
                  <span className="badge badge-soft-success ms-2">
                    <i className="ti ti-circle-filled me-1 fs-5" />
                    Active
                  </span>
                </div>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>Class Name</p>
                        <span>III</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>Section</p>
                        <span>A</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>No of Subjects</p>
                        <span>05</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>No of Students</p>
                        <span>25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /View Classes */}
      </>
    </div>
  );
};

export default Classes;
