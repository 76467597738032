import axios from "axios";
import { toast } from "react-toastify";
import { create } from "zustand";
const appstore = create((set, get) => ({
  isLoading: false,
  isLogin: true,
  userData: { role: "ADMIN" },
  loginIdentify: "ADMIN",
  allStudents: [],
  allteacher: [],
  allStaff: [],
  allClassRoom: [],
  allSubjects: [],
  timeTable: [],
  allClasses:[], 
  setUserData: (data) => {
    set({ userData: data });
  },
  setLoginIdentify: (data) => {
    set({ loginIdentify: data });
  },
  setLogin: (data) => {
    set({ isLogin: data });
  },
  setAllStudents: (data) => {
    set({ allStudents: data });
  },
  setAllTeacher: (data) => {
    set({ allteacher: data });
  },
  setAllStaff: (data) => {
    set({ allStaff: [...data] });
  }, 
  setAllSubjects: (data) => {
    set({ allSubjects: data });
  }, 
  setAllClassRoom: (data) => {
    set({ allClassRoom: data });
  }, 
  setAllClasses: (data) => {
    set({ allClasses: data });
  }, 
  setTimetable: (data) => {
    set({ timeTable: data });
  }, 
  appAxios: ({
    path,
    obj,
    admin,
    post,
    token_user,
    del,
    patch,
    put,
    contentType,
  }) => {
    // console.log("sadsdfsf", process.env);
    const url = process.env.REACT_APP_PUBLIC_APP_BASE_URL;
    const token = admin
      ? localStorage.getItem(process.env.REACT_APP_PUBLIC_APP_TOKEN + "_admin")
      : localStorage.getItem(
          process.env.REACT_APP_PUBLIC_APP_TOKEN + "_organization"
        );

    const tokenApp = localStorage.getItem(
      process.env.REACT_APP_PUBLIC_APP_TOKEN + "_ulai_user"
    );
    set({ isLoading: true });
    return new Promise(async (resolve, reject) => {
      try {
        let resp;
        if (put) {
          resp = await axios.put(
            path.startsWith("http") ? path : url + path,
            obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + (path.startsWith("http") ? tokenApp : token),
              },
            }
          );
        } else if (patch) {
          resp = await axios.patch(
            path.startsWith("http") ? path : url + path,
            obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + (path.startsWith("http") ? tokenApp : token),
              },
            }
          );
        } else if (del) {
          resp = await axios.delete(
            path.startsWith("http") ? path : url + path,
            obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + (path.startsWith("http") ? tokenApp : token),
              },
            }
          );
        } else {
          resp = post
            ? await axios.post(
                path.startsWith("http") ? path : url + path,
                obj,
                {
                  headers: {
                    "Content-Type": contentType
                      ? contentType
                      : "application/json",
                    Authorization:
                      "Bearer " + (path.startsWith("http") ? tokenApp : token),
                  },
                }
              )
            : await axios.get(path.startsWith("http") ? path : url + path, {
                headers: {
                  Authorization:
                    "Bearer " + (path.startsWith("http") ? token : token),
                },
              });
        }

        if (resp?.data?.logout) {
          admin
            ? localStorage.removeItem(
                process.env.REACT_APP_PUBLIC_APP_TOKEN + "_admin"
              )
            : localStorage.removeItem(
                process.env.REACT_APP_PUBLIC_APP_TOKEN + "_user"
              );

          localStorage.clear();
          set({ isLogin: false });
          set({ userData: {} });
          //   history.push(admin ? "/admin" : "/user");
        }

        if (!resp.data.success) {
          set({ isLoading: false });
          // setData({
          // 	...data,
          // 	loading: false,
          // 	snack: resp?.data?.msg ? true : false,
          // 	snack_msg: resp?.data?.msg || 'Great',
          // 	snack_success: false,
          // });
          // setTimeout(() => {
          // 	setData({
          // 		...data,
          // 		loading: false,
          // 		snack: false,
          // 		snack_msg: resp?.data?.msg || 'Great',
          // 		snack_success: false,
          // 	});
          // }, 4000);
        } else {
          set({ isLoading: false });
          // setData({
          // 	...data,
          // 	loading: false,
          // 	snack: resp?.data?.msg ? true : false,
          // 	snack_msg: resp?.data?.msg || 'Great',
          // 	snack_success: true,
          // });
          // setTimeout(() => {
          // 	setData({
          // 		...data,
          // 		loading: false,
          // 		snack: false,
          // 		snack_msg: resp?.data?.msg || 'Great',
          // 		snack_success: true,
          // 	});
          // }, 4000);
        }
        set({ isLoading: false });

        // setData({ ...data, loading: false, snack: resp?.data?.msg ? true : false, snack_msg: resp?.data?.msg || "Great" })
        // setTimeout(() => {
        //     setData({ ...data, loading: false, snack: false, snack_msg: resp?.data?.msg || "Great" })
        // }, 4000);

        resolve(resp);
      } catch (err) {
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Something Went Wrong"
        );

        set({ isLoading: false });
        // try {
        //   axios.post(
        //     `${process.env.NEXT_PUBLIC_APP_BASE_URL}/api/errorlogging/add`,
        //     {
        //       apiName: path,
        //       errorMsg: err?.response?.data?.message
        //         ? err?.response?.data?.message
        //         : "Something Went Wrong",
        //       statusCode: err?.response?.status,
        //     },
        //     {
        //       headers: {
        //         "Content-Type": "application/json",
        //       },
        //     }
        //   );
        // } catch (error) {}
        // if (err.response) {
        //   // console.log(err.response.data);
        // }
      }
    });
  },
}));
export default appstore;
